<template>
    <div class="action-premium-survey-end">
        <div class="message">
            <div class="message-top" v-html="$translate('ACTION_PREMIUM_SURVEY_END_TITLE')" />
            <div class="message-button" v-if="!content.start && showButton">
                <button
                    @click="showSurvey"
                    class="btn btn-primary"
                    v-html="$translate('ACTION_PREMIUM_SURVEY_BUTTON')"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionPremiumSurveyEnd',
    props: ['message'],
    data: () => ({
        showButton: true,
        survey: [],
    }),
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        user() {
            return this.content.user || {}
        },
        profile() {
            return this.chat.user.profile
        },
    },
    mounted() {
        this.surveys = Object.values(JSON.parse(this.content.survey_result) || {})
    },
    methods: {
        async showSurvey() {
            let surveyResult = ''
            this.surveys.map(s => {
                surveyResult = surveyResult + `<span class="f-bold">Q: ${s.question.replace(/<br>/g, '')}</span><br>`
                surveyResult = surveyResult + `A: ${this.parseAnswer(s.answer)}<br><br>`
            })
            this.$modal.basic({
                body: surveyResult,
                survey: true,
            })
        },
        parseAnswer(answer) {
            if (Array.isArray(answer)) {
                return answer
            } else if (typeof answer === 'object') {
                return Object.values(answer).map(e => `${e.question} : ${e.answer}`)
            } else return answer
        },
    },
}
</script>

<style lang="scss" scoped>
.action-premium-survey-end {
    width: calc(100% - 8px);

    .message {
        margin: 14px !important;
        font-size: 16px;
        font-family: NotoSans-Medium;
        line-height: 1.33;
        text-align: left;
        color: black;

        .message-button {
            display: flex;
            margin: 14px 0;
        }
    }
}
</style>
